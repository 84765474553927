<div
	*ngIf="(options?.hidden$ | async) === false"
	class="o-flex o-flex--vcenter action-bar--wrapper {{ options?.className }}"
	[ngClass]="{
		'disabled action-bar--disabled': options?.disabled$ | async,
		'o-flex--flex-start': options?.actionSlot === 'START',
		'o-flex--flex-end': options?.actionSlot === 'END' || !options?.actionSlot
	}">
	<ng-content select="start"></ng-content>
	<button
		*ngFor="let action of options?.actionItems"
		type="button"
		(click)="action.onClick()"
		[ngClass]="{
			'mr-4': options?.actionSlot === 'START',
			'ml-4': options?.actionSlot === 'END' || !options?.actionSlot
		}"
		[class]="'btn ' + (action.buttonClass || 'btn--theme')"
		[disabled]="action.disabled$ | async">
		<lu-icon *ngIf="action.icon" class="btn__ico" [icon]="action.icon"></lu-icon>
		<span class="btn__txt">{{ action.label || 'submit' | translate }}</span>
	</button>
	<ng-content select="end"></ng-content>
</div>
