import { Component, Input, ViewChild } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { LuDropDownItem } from './angie-drop-down.model';

@Component({
	selector: 'angie-drop-down',
	templateUrl: './angie-drop-down.component.html',
	styleUrls: ['./angie-drop-down.component.scss']
})
export class AngieDropDownComponent {
	@ViewChild(BsDropdownDirective) dropdown: BsDropdownDirective;
	@Input() icon = 'lu-kebab-menu';
	@Input() dropDownItems: LuDropDownItem[];
	@Input() dropDownDisabled: boolean;
	@Input() popoverPosition: 'left | right';
	@Input() customClass: string;
	@Input() popoverPositionCustomClass: string;
	// Need to find a better way to handle closing since this now refers to the drop menu not to grid-row
	onMouseLeave(): void {
		if (this.dropdown?.isOpen) {
			this.dropdown.hide();
		}
	}

	onClick(item: LuDropDownItem, index: number): void {
		if (this.dropDownDisabled) return;
		item.onClick(item, index);
	}
}
