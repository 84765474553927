import { LuUserRoleCode } from 'src/app/core/core.models';
import { SearchOptions } from '../models/angie-smart-search.models';
import { SearchType } from './search-types.const';

/**
 * Filter Search Types - permission check when do we show specific filters based on search type, portal meta and role
 */
export const filterSearchTypes = (searchTypes: SearchType[], portalMeta, userRole): SearchType[] => {
	return searchTypes.filter(searchType => {
		switch (searchType.type) {
			case SearchOptions.LEARNING_PATHS:
				return userRole === LuUserRoleCode.MEMBER && portalMeta.enable_learning_paths;
			case SearchOptions.CATALOG:
				return portalMeta.catalog_enabled;
			case SearchOptions.RESOURCES:
				return userRole === LuUserRoleCode.MEMBER && !!portalMeta.learner_resources_count;
			case SearchOptions.USERS:
				return userRole === LuUserRoleCode.MANAGER || userRole === LuUserRoleCode.ADMIN;
			case SearchOptions.GROUPS:
				return userRole === LuUserRoleCode.ADMIN && !!portalMeta.get_count_of_groups;
			default:
				return true;
		}
	});
};
