import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import {
	DefaultFieldWrapperComponent,
	LuButtonInputModule,
	LuChipFormlyModule,
	LuDatetimeFormlyPickerModule,
	LuInputModule,
	LuRadioModule,
	LuSelectFormlyModule,
	LuSwitchFormlyModule,
	LuTextareaModule,
	LuTextFormlyModule,
	LuToggleButtonModule,
	LuTypeaheadFormlyModule
} from 'lu-formly';
import { dataService as SelectDataServiceToken, scrollService as SelectScrollServiceToken } from 'lu-select';
import { dataService as TypeaheadDataServiceToken, scrollService as TypeaheadScrollServiceToken } from 'lu-typeahead';
import { DataService, ScrollService } from 'src/app/core';
import { FormlyMultiCheckboxModule } from '../components/formly-fields/multicheckbox/multicheckbox.module';
import { LuButtonFormlyModule } from '../components/lu-button-formly/lu-button-formly.module';
import { LuCKEditorFormlyModule } from '../components/lu-ckeditor-formly/lu-ckeditor-formly.module';
import { RadioAndMultiCheckBoxDisableFix } from './formly.config';

const exports = [FormlyModule, ReactiveFormsModule];

const imports = [
	FormlyModule.forChild({
		wrappers: [
			{
				name: 'form-field',
				component: DefaultFieldWrapperComponent
			}
		],
		extensions: [
			{
				name: 'radio-and-multicheckbox-disable-fix',
				extension: new RadioAndMultiCheckBoxDisableFix()
			}
		]
	}),
	ReactiveFormsModule,
	LuInputModule,
	LuButtonInputModule,
	LuChipFormlyModule,
	LuTypeaheadFormlyModule.forRoot({
		dataService: {
			provide: TypeaheadDataServiceToken,
			useExisting: DataService
		},
		scrollService: {
			provide: TypeaheadScrollServiceToken,
			useExisting: ScrollService
		}
	}),
	LuSelectFormlyModule.forRoot({
		dataService: {
			provide: SelectDataServiceToken,
			useExisting: DataService
		},
		scrollService: {
			provide: SelectScrollServiceToken,
			useExisting: ScrollService
		}
	}),
	LuSwitchFormlyModule,
	LuTextareaModule,
	LuTextFormlyModule,
	LuDatetimeFormlyPickerModule,
	LuRadioModule,
	FormlyMultiCheckboxModule,
	LuCKEditorFormlyModule,
	LuButtonFormlyModule,
	LuToggleButtonModule
];

// Import modules only that are required for formly usage
@NgModule({
	imports,
	exports
})
export class FormlySharedModule {}
