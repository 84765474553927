import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isNil } from 'lodash-es';
import { isObservable, of } from 'rxjs';
import { ActionBarConfig, ActionBarItemOption, ActionBarOptions } from './angie-action-bar.model';

@Component({
	selector: 'angie-action-bar',
	templateUrl: './angie-action-bar.component.html',
	styleUrls: ['./angie-action-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AngieActionBarComponent {
	private _options: ActionBarOptions;
	@Input() set config(data: ActionBarConfig) {
		if (!data) return;
		this._options = this.mapConfig(data);
	}
	get options(): ActionBarOptions {
		return this._options;
	}
	private mapConfig(config: ActionBarConfig): ActionBarOptions {
		const { className, actionSlot, disabled$, hidden$, actionItems } = config;
		const options: ActionBarOptions = { className, actionSlot, actionItems: [] };
		options.hidden$ = isObservable(hidden$) ? hidden$ : of(hidden$);
		options.disabled$ = isObservable(disabled$) ? disabled$ : of(disabled$);
		options.actionItems = actionItems.map(item => {
			const { buttonClass, icon, label, onClick, disabled$ } = item;
			const actionItem: ActionBarItemOption = { buttonClass, icon, label, onClick };
			if (!isNil(disabled$)) {
				actionItem.disabled$ = !isObservable(disabled$) ? of(disabled$) : disabled$;
			}
			return actionItem;
		});
		return options;
	}
}
