import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LuIconModule } from 'lu-icon';
import { AngieActionBarComponent } from './angie-action-bar.component';

@NgModule({
	exports: [AngieActionBarComponent],
	imports: [CommonModule, TranslateModule, LuIconModule],
	declarations: [AngieActionBarComponent]
})
export class AngieActionBarModule {}
