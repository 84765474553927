<div class="dropdown-wrapper" [ngClass]="customClass">
	<div *ngIf="dropDownItems?.length" class="dots-ddm" dropdown>
		<button type="button" class="dots-ddm__btn link-c def-focus" dropdownToggle [title]="'lup.a11y.grid_item.more_options' | translate" [ngClass]="{'ddm-disabled': dropDownDisabled}">
			<lu-icon class="dots-ddm__icon" customClass="lui" [icon]="icon"></lu-icon>
		</button>
		<ul role="menu" [ngClass]="popoverPositionCustomClass" class="dropdown-menu dropdown-menu-{{ popoverPosition || 'right' }}" *dropdownMenu (mouseleave)="onMouseLeave()">
			<li role="none" class="dropdown-menu__item" [ngClass]="item.class" *ngFor="let item of dropDownItems; let i = index">
				<a *ngIf="!item.hidden" role="menuitem" (click)="onClick(item, i)" class="dropdown-menu__btn" [ngClass]="{'ddm-disabled': item?.disabled}">
					<lu-icon customClass="lui lui--l" [ngClass]="item.iconClass" [type]="item.iconType" [icon]="item.icon" *ngIf="item.icon"></lu-icon>{{ item.label }}
				</a>
			</li>
		</ul>
	</div>
</div>
