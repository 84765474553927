import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'truncateTextPipe',
	pure: false
})
export class TruncateTextPipe implements PipeTransform {
	transform(input: string, maxLength: number = 200): string {
		if (!input || input.length === 0) {
			return '';
		}

		// Convert to plain text by removing HTML tags
		let plainText = input ? String(input).replace(/<[^>]+>/gm, '') : '';

		// Truncate the text if it exceeds the max length
		if (plainText.length > maxLength) {
			plainText = plainText.substring(0, maxLength - 1) + '... ';
		}

		return plainText;
	}
}
