import { DsConfig as SharedDsConfig } from 'lu-services';
import { AdvancedPasswordPolicy } from '../angie-shared/components/advanced-password/models/advanced-password.models';
import { UserService } from '../angie-shared/services/user.service';
import { DateFormat } from '../globals';
import { Language } from './services/language.service';
import { StateService } from './services/state.service';

export interface IMapString<T> {
	[index: string]: T;
}

export interface IMapNumber<T> {
	[index: number]: T;
}

/**
 * StringOrNumber type used in case where identifier can be string or number
 */
export type StringOrNumber = string | number;

/**
 * Generic ValueOf/PropertyOf type - handy for typing elements when iterating objects/object properties
 */
export type ValueOf<T> = T[keyof T];
export type PropertyOf<T> = [keyof T];

/**
 * Comparator function type;
 */
export type CompareFn<T> = (a: T, b: T) => number;

/**
 * PopupMessage (Toastr) definition
 */
export interface PopupMessage {
	/**
	 * Toastr type - maybe we need explicitly some other type
	 */
	type?: string;
	/**
	 * Title of the toastr message
	 */
	title?: string;
	/**
	 * Toastr message content
	 */
	message?: string;
}

/**
 * PopupMessageConfig
 *
 * Used to configure our generic response errors/success handle
 */
export interface PopupMessageConfig {
	/**
	 * Success Message definition (type/title/message)
	 */
	successMessage?: PopupMessage;
	/**
	 * Fail Message definition (type/title/message)
	 */
	failMessage?: PopupMessage;
}

/**
 * DsConfig - basic data service which we pass to our data service methods
 */
export interface DsConfig extends SharedDsConfig {}

/**
 * LayoutType for routing data
 */
export enum LayoutType {
	BASE = '',
	DEFAULT = 'lu-container',
	FLUID = 'lu-container-fluid',
	LARGE = 'lu-container lu-container--xl'
}

/**
 * Lup Jobs - we need key to know which job we should start
 */
export enum LupJobs {
	DOWNLOAD_TRAINING_HISTORY = 1,
	DOWNLOAD_CERTIFICATE = 2,
	EXPORT_COURSES_TO_EXCEL = 3,
	EXPORT_USERS_TO_EXCEL = 4,
	EXPORT_RESOURCES_TO_EXCEL = 5,
	EXPORT_INVITES_TO_EXCEL = 6,
	EXPORT_PENDING_ENROLLMENTS_TO_EXCEL = 7,
	INCREMENT_WORK_DONE = 8,
	EXPORT_REPORTS = 9,
	DOWNLOAD_EXAM_RESULTS = 10,
	DOWNLOAD_ASSIGNMENT = 11,
	BATCH_IMPORT_SAMPLE = 12,
	EXPORT_EXAM = 13,
	EXPORT_QUESTION_POOL = 14,
	EXPORT_TERMS_DATA = 15,
	EXPORT_COURSE_REVIEWS = 16,
	EXPORT_ARCHIVED_TRAINING_HISTORY = 17,
	DYNAMIC_RULES_PREVIEW = 18,
	EXPORT_DYNAMIC_RULES_PREVIEW = 19,
	DOWNLOAD_REPORT_AS_CSV = 21,
	EXPORT_GROUPS_TO_EXCEL = 22,
	EXPORT_ALL_COURSE_REVIEWS = 23,
	EXPORT_SIGNED_ESIGNATURE_MODULES = 24,
	EXPORT_BULK_ACTIONS_TO_EXCEL = 25
}

/**
 * BackgroundJobStatus - once we start the job, we check the progress. It returns status
 */
export enum BackgroundJobStatus {
	NOT_STARTED = 1,
	IN_PROGRESS = 2,
	COMPLETED = 3,
	NOT_FOUND = 4,
	FAILED = 5,
	ABORTED = 6,
	UNKNOWN = 7,
	TIMEOUT = 8,
	IN_QUEUE = 9
}

/**
 * BackgroundJob - default background job response - contains all of the data related to specific job
 */
export interface BackgroundJob<T = any> {
	/**
	 * Job ID from database
	 */
	id: number;
	/**
	 * Job reference - unique per each job started
	 */
	job_reference: string;
	/**
	 * Check for job status after we starting doing pooling
	 */
	status_id: BackgroundJobStatus;
	/**
	 * Work done - so we have percentage indicator on UI (fake one generated on BE since we don't have job status tracker)
	 */
	work_done: number;
	/**
	 * How much percentages are left to be done
	 */
	work_to_do: number;
	/**
	 * If background job has errors, handle it accordingly
	 */
	has_errors: boolean;
	/**
	 * Flash message if we need to trigger toastr
	 */
	flash_msg: string;
	/**
	 * Percentage complete - same as work_done property
	 */
	percentage_complete: number;
	/**
	 * Generate s3_url - this one is usually if we need to generate pdf or some other type of document in BE so we show download URL on UI
	 */
	s3_url: string;
	/**
	 * job_data - we expect generic Base or List response in our payload
	 */
	job_data?: BaseResponse<T> | ListResponse<T>;
}

export interface BackgroundJobResponse {
	error: string;
	job?: BackgroundJob;
	data?: BackgroundJob;
	meta: any;
}

/**
 * Operations options used for BulkActions
 */
export enum OperationOptions {
	MARKCOMPLETE = 'MARKCOMPLETE',
	UNENROLL = 'UNENROLL',
	SEND_REMINDER = 'SENDREMINDER'
}

/**
 * Domain options used for BulkActions
 */
export enum DomainOptions {
	ENROLLMENTS = 'Enrollments'
}

/**
 * Week Days (if needed to get listed out somewhere or used for translations) - for now widgets...
 */
export enum WEEK_DAYS {
	MONDAY = 1,
	TUESDAY = 2,
	WEDNESDAY = 3,
	THURSDAY = 4,
	FRIDAY = 5,
	SATURDAY = 6,
	SUNDAY = 7
}

/**
 * Months (if needed to get listed out somewhere or used for translations) - for now widgets...
 */
export enum MONTHS {
	JANUARY = 0,
	FEBRUARY = 1,
	MARCH = 2,
	APRIL = 3,
	MAY = 4,
	JUNE = 5,
	JULY = 6,
	AUGUST = 7,
	SEPTEMBER = 8,
	OCTOBER = 9,
	NOVEMBER = 10,
	DECEMBER = 11
}

/**
 * List response for our data service - in charge for fetching any data that contains pagination
 */
export interface ListResponse<T> {
	/**
	 * Payload data that we need - generic array
	 */
	data: Array<T>;
	/**
	 * Does it has next so we have indicator if we allow additional fetch or not for next page
	 */
	has_next: boolean;
	/**
	 * Page number - every additional fetch increases this number to fetch the next page
	 */
	page: number;
	/**
	 * Page size of payload - can be customized
	 */
	page_size: number;
	/**
	 * State change - Angie state change track - NOT yet implemented inside our app
	 */
	state_change?: StageChange;

	/**
	 * Loading status - used by TableComponent to know loading state
	 */
	loading?: boolean;
}

/**
 * Base(Generic) response for our data service
 */
export interface BaseResponse<T> {
	/**
	 * All data fetched from BE will be store here regarding API
	 */
	data: T;
	/**
	 * State Change - tracks any portal meta that is changed and it will update UI based on this one
	 */
	state_change?: StageChange;
}

/**
 * Base list Request - in charge for handling some actions that include pagination (lu-grid as example)
 */
export interface BaseListRequest {
	/**
	 * Payload size of fetched data
	 */
	page_size?: number;
	/**
	 * Page indicator - to get data from next page on fetch
	 */
	page?: number;
	/**
	 * Sort by - we specify string by which we do sorting
	 */
	sort?: string;
}

/**
 * BaseModel - For LuUser if common for all of the models
 */
export interface BaseModel {}

/**
 * LuUser - basic information about logged in user
 */
export interface LuUser extends BaseModel {
	/**
	 * User id
	 */
	id: number;
	pm_id: number;
	/**
	 * Display name that user set inside his profile settings
	 */
	display_name: string;
	/**
	 * User's username
	 */
	username: string;
	/**
	 * First name set inside profile settings
	 */
	first_name: string;
	/**
	 * Last name set inside profile settings
	 */
	last_name: string;
	/**
	 * Email set inside profile settings
	 */
	email: string;
	/**
	 * User's role
	 */
	role_code: LuUserRoleCode;
	/**
	 * Avatar URL - fetch avatar image
	 */
	avatar: ProfileAvatar;
	permissions: LuUserPermissionsModel;
	impersonation_active: boolean;
}

/**
 * Profile Avatar
 *
 * served for profile image object
 */
export interface ProfileAvatar {
	/**
	 * Image id
	 */
	id: number;
	/**
	 * Small image url
	 */
	small_url: string;
	/**
	 * Large image url
	 */
	large_url: string;
	/**
	 * Image alt tag
	 */
	alt_tag: string;
}

/**
 * User Role definition
 */
export enum LuUserRoleCode {
	EXPIRED = -1,
	DISABLED = 0,
	MEMBER = 1,
	ADMIN = 2,
	INSTRUCTOR = 3,
	MANAGER = 4
}

/**
 * Purchase IP Blocked code definition
 */
export enum PurchaseIpBlockedCode {
	USE_PORTAL_DEFAULT = 0,
	ONLY_ALLOWED_THIS_TIME = 1,
	ALWAYS_ALLOWED = 2,
	PURCHASE_BLOCKED = 4
}

/**
 * Portal Membership Permissions
 */
export enum PortalMembershipPermissions {
	CAN_ENROLL = 'can_enroll',
	CAN_MARK_COMPLETE = 'can_mark_complete',
	TUTOR_CAN_CREATE_COURSES = 'tutor_can_create_courses',
	TUTOR_CAN_EDIT_THEIR_COURSES = 'tutor_can_edit_their_courses',
	CAN_DELETE_USERS = 'can_delete_users',
	CAN_UNENROLL_USERS = 'can_unenroll_users',
	CAN_MOVE_GROUPS = 'can_move_groups',
	CAN_ACT_AS_INSTRUCTOR = 'can_act_as_instructor',
	CAN_ONLY_MANAGE_ILTS = 'can_only_manage_ilts',
	CAN_MANAGE_TRAININGS = 'can_manage_trainings',
	CAN_MANAGE_SESSIONS = 'can_manage_sessions',
	ADMIN_CAN_ACCESS_PORTAL_SETTINGS = 'admin_can_access_portal_settings',
	CAN_ADD_USERS_TO_SESSION = 'can_add_users_to_session'
}

/**
 * On State change will trigger action which would be some of these enum types.
 */
export enum AngieClientAction {
	MESSAGES_RELOAD = 'messages_reload'
}

/**
 * State change, this is going to be read from data service - we're going to update UI according to it
 */
export interface StageChange {
	/**
	 * state changes action - all of the state changes are going to be defined as enum
	 */
	action: AngieClientAction;
	/**
	 * don't use it atm - additional data that would potentially be required
	 */
	payload?: any;
}

/**
 * Secondary Navigation item permission check
 *
 * parameters that are passed inside permissionCheck callback function
 */
export interface LuSecondaryNavigationPermissions {
	stateService: StateService;
	userService: UserService;
}

/**
 * Secondary Navigation item permission check
 *
 * parameters that are passed inside permissionCheck callback function
 */
export interface LuPrimaryNavigationPermissions {
	stateService: StateService;
	userService: UserService;
}

/**
 * Enrollment Statuses
 */
export enum EnrollmentStatuses {
	WAITLISTED = -4,
	PARTIALLY_ATTENDED = -3,
	CANCELLED = -2,
	NO_SHOW = -1,
	NOT_STARTED = 1,
	IN_PROGRESS = 2,
	COMPLETED = 3,
	PASSED = 4,
	FAILED = 5,
	PENDING_REVIEW = 6,
	CLOSED = 7,
	PAST_DUE = 8
}

/**
 * Course Component Type
 */
export enum ComponentType {
	EXAM = 1,
	SCORM = 2,
	PAGE = 3,
	ASSIGMENT = 4,
	SESSION = 5,
	TRAININGS = 6
}

/**
 * Primary Spinner Config
 */
export interface PrimarySpinnerConfig {
	/**
	 * Show/Hide primary spinner
	 */
	show: boolean;
}

/**
 * ValueLabel interface - usually used for select / typeahead etc
 */
export interface ValueLabel {
	/**
	 * Value - usually number or string as key that is sent to BE
	 */
	value: any;
	/**
	 * Label - usually shown in UI for specific definition
	 */
	label: string;
}

/**
 * Base Mapper, takes key and label, outputs to ValueLabel model
 */
export abstract class BaseMapper {
	/**
	 * Constructor
	 */
	protected constructor(
		protected key: any,
		protected label: string
	) {}

	/**
	 * Build All static
	 *
	 * Return ValueLabel, we pass object and get array of it's values
	 */
	public static buildAll(mapper: any): ValueLabel[] {
		const toReturn: ValueLabel[] = [];
		Object.keys(mapper).map(key => {
			toReturn.push(mapper[key]);
		});
		return toReturn;
	}
}

/**
 * Custom user data definitions
 */
export enum CustomUserDataFieldType {
	STRING = 1,
	DECIMAL = 2,
	INTEGER = 3,
	STRING_CHOICE = 4,
	DECIMAL_CHOICE = 5,
	INTEGER_CHOICE = 6,
	DATE = 7
}

/**
 * Custom user data definitions types
 */
export enum CustomUserDataSelectType {
	SINGLE_SELECT = 1,
	MULTI_SELECT = 2,
	NUMERIC_SELECT = 3
}

// TODO: add comments
export interface CustomUserData {
	id: number;
	portal_id: string;
	choice_select_type?: CustomUserDataSelectType;
	field_type?: CustomUserDataFieldType;
	predefined_values?: any;
	field_label?: string;
	is_mandatory?: boolean;
	learner_can_edit?: boolean;
}

/**
 * Custom user data definitions types
 */
export enum UserActivity {
	USER_LAUNCHED_COURSE = 0,
	USER_LOGGED_IN = 1,
	USER_COMPLETED_COURSE = 2,
	USER_FAILED_COURSE = 3,
	USER_CREATED_PORTAL = 4,
	USER_CREATED_GROUP = 5,
	USER_CREATED_COURSE = 6,
	USER_CREATED_COMPONENT = 7,
	USER_SIGNED_UP = 8,
	USER_ACTIVATED_ACCOUNT = 9,
	USER_ENROLLED_IN_COURSE = 10,
	USER_UNENROLLED_FROM_COURSE = 11,
	USER_COURSE_COMPONENT_IN_PROGRESS = 12,
	USER_COURSE_COMPONENT_COMPLETED = 13,
	USER_LAUNCHED_SCORM_COMPONENT_NO_CREDIT = 14,
	USER_LAUNCHED_SCORM_COMPONENT = 15,
	USER_PASSED_COURSE = 16,
	USER_COMPLETED_COMPONENT = 17,
	USER_FAILED_COMPONENT = 18,
	USER_PASSED_COMPONENT = 19,
	USER_LOGGED_OUT = 20,
	USER_COMPLETED_EXAM = 21,
	USER_PASSED_EXAM = 22,
	USER_FAILED_EXAM = 23,
	USER_ENROLLED_USER_IN_COURSE = 24,
	USER_UNENROLLED_USER_FROM_COURSE = 25,
	USER_STARTED_EXAM = 26,
	USER_PUBLISHED_NEW_COURSE_VERSION = 27,
	USER_ARCHIVED_COURSE = 28,
	USER_DELETED_COURSE = 29,
	USER_REVERTED_REVISION = 30,
	USER_AWARDED_CERTIFICATE = 31,
	USER_ACHIEVED_CREDITS = 32,
	USER_STARTED_SURVEY = 33,
	USER_COMPLETED_SURVEY = 34,
	USER_COMPLETED_KNOWLEDGECHECK = 35,
	USER_DOWNLOADED_RESOURCE = 36,
	USER_CHOSE_SESSION = 37,
	USER_ATTENDED_SESSION = 38,
	USER_CANCELLED_SESSION = 39,
	USER_NO_SHOW_SESSION = 40,
	USER_CREATED_COURSE_BUNDLE = 41,
	USER_DELETED_COURSE_BUNDLE = 42,
	USER_PARTIALLY_ATTENDED_SESSION = 43,
	USER_REACHED_GAMIFICATION_LEVEL = 44,
	USER_ACHIEWED_ACTIVITY_AWARD = 45,
	USER_ACHIEWED_LEARNING_AWARD = 46
}

export interface LuFooter {
	footer_links: string[];
	portal_title: string;
	remove_powered_by: boolean;
}

/**
 * my-account.json response interface - loaded through the state service on initial load
 *
 */
export interface AccountMeta {
	account: LuUser;
	language: Language;
	footer: LuFooter;
	unread_messages: number;
	portal_meta: PortalMeta;
	flash_message: FlashMessage;
	chat_settings: ChatSettings;
	toastr_messages: any;
	timezones: LuStateTimezone[];
	ck_editor: CkEditor;
}

/**
 * Portalmeta interface - defines all variations that are affecting portal UI and behaviour
 *
 * Data is loaded thorough state service and used across the app thorough state service usage
 */
export interface PortalMeta {
	/**
	 * Current portal ID
	 */
	id: number;
	/**
	 * Enable/Disable learning paths
	 */
	enable_learning_paths: boolean;
	/**
	 * Enable/Disable Custom SMTP
	 */
	custom_smtp: boolean;
	/**
	 * New Learning path report flag
	 */
	learning_path_report: boolean;
	/**
	 * Enable/Disable catalog
	 */
	catalog_enabled: boolean;
	/**
	 * Portal date format
	 */
	date_format: DateFormat;
	/**
	 * Enable/Disable learner resources
	 */
	learner_resources_count: number;
	/**
	 * Number of groups the current portal has
	 */
	get_count_of_groups: number;
	/**
	 * Show/Hide desk support
	 */
	show_desk_support: boolean;
	/**
	 * Are multiple languages allowed?
	 */
	allow_multiple_languages: boolean;
	/**
	 * Portal title
	 */
	title: string;
	/**
	 * Check if ecommerce is enabled inside JRIMA
	 */
	ecomms_enabled: boolean;
	/**
	 * Check if forums are allowed and enabled
	 */
	forums_enabled: boolean;
	/**
	 * Check if forums are enabled
	 */
	learner_view_forums_enabled: boolean;
	/**
	 * Check if portal has library
	 */
	has_library: boolean;
	/**
	 * Check if portal can have other portals and licenses
	 */
	show_clients_and_licenses: boolean;
	/**
	 * Check if we allow profile images on portal
	 */
	allow_profile_images: boolean;
	/**
	 * Check if we allow login with usernames on our portal
	 */
	login_with_usernames: boolean;
	/**
	 * Is messaging disabled?
	 */
	disable_messaging: boolean;
	/**
	 * Check if gamification is enabled
	 */
	gamification_enabled: boolean;
	/**
	 * Check if ilts are enabled on our portal
	 */
	enable_ilts: boolean;
	/**
	 * Check if New ILTs are enabled on our portal
	 */
	trainings_enabled: boolean;
	/**
	 * Show Calendar link in middle navigation on dashboard
	 */
	show_calendar_on_dash: boolean;
	/**
	 * Is our app loaded through salesforce?
	 */
	loaded_in_salesforce: boolean;
	/**
	 * Show billing tab in primary navigation?
	 */
	show_billing_tab: boolean;
	/**
	 * Are Scheduled reports enabled/disabled?
	 */
	scheduled_reports: boolean;
	/**
	 * Are Scheduled reports in Report Builder enabled/disabled?
	 */
	report_schedules: boolean;
	/**
	 * Is Salesforce enabled/disabled?
	 */
	allow_sales_force: boolean;
	/**
	 * Is LRS enabled/disabled?
	 */
	allow_lrs: boolean;
	/**
	 * Are webinars enabled/disabled?
	 */
	allow_webinars: boolean;
	/**
	 * Is API enabled/disabled
	 */
	allow_api: boolean;
	/**
	 * Are webhooks enabled/disabled
	 */
	allow_webhooks: boolean;
	/**
	 * Is SAML enabled/disabled
	 */
	allow_saml: boolean;
	/**
	 * Is SQSSO enabled/disabled
	 */
	allow_sqsso: boolean;
	/**
	 * Is recaptcha enabled/disabled
	 */
	allow_recaptcha: boolean;
	/**
	 * Is chat integrations enabled/disabled
	 */
	allow_chat_integrations: boolean;
	/**
	 * Is content_embed enabled/disabled
	 */
	allow_content_embed: boolean;
	/**
	 * Is FF add users on session enabled
	 */
	add_users_to_session_enabled: boolean;
	/**
	 * Show/Hide portal jumper
	 */
	show_portal_jumper: boolean;

	/**
	 * Can create/edit trainings (ILT)
	 */
	can_access_live_trainings: boolean;
	trainings_read_only_enabled: boolean;

	/**
	 * Extending manager role with instructor permissions
	 */
	extending_manager_role: boolean;
	manager_strict_ilt_permission_enabled: boolean;
	portal_trust_enabled: boolean;
	show_ssl_warning: boolean;
	associations_membership_types: AssociationMembershipType[];
	password_policy_configuration: AdvancedPasswordPolicy;
	/**
	 * Determines if portal has membership types enabled
	 */
	have_membership_types_enabled: boolean;
	/**
	 * Determines if portal has ability to create virtual sessions for the new ILTs
	 */
	virtual_ilts_enabled: boolean;
	portal_images: PortalImage[];
	oauth_enabled: boolean;
	oidc_enabled: boolean;
	reports_builder: boolean;
	/**
	 * Are questions disabled on portal?
	 */
	disable_questions: boolean;
	/**
	 * Determine if the currently logged in user should see course authoring facilities
	 */
	allow_course_authoring: boolean;
	/**
	 * Default portal timezone
	 */
	timezone: string;
	/**
	 * Is user allowed to opt-out from emails/messages?
	 */
	allow_email_opt_out: boolean;
	/**
	 * Hide password fields meta
	 */
	hide_password_fields: boolean;
	/**
	 * If 2FA is allowed on JRIMA side
	 */
	two_factor_authentication_allowed: boolean;
	/**
	 * If 2FA is enabled in portal settings
	 */
	two_factor_authentication_enabled: boolean;
	/**
	 * Forget me feature enabled
	 */
	enable_forget_me: boolean;
	/**
	 * Sales tax country code
	 */
	sales_tax_country_code: string;
	/**
	 * Current portal url
	 */
	portal_url: string;
	/**
	 * If training overbook is enabled on JRIMA side
	 */
	training_overbook_enabled: boolean;
	/**
	 * Custom user data definition for specific portal
	 */
	cud_definitions: CustomUserData[];
	/**
	 * Does portal has children
	 */
	portal_has_children: boolean;
	/**
	 * Can non admin user edit login fields
	 */
	readonly_login_field: boolean;
	/**
	 * Can users edit profile data
	 */
	readonly_profile: boolean;

	/**
	 * Are ical ILT notifications enabled
	 */
	enable_ical_attachments: boolean;

	/**
	 * Proxy User enabled
	 */
	proxy_user_enabled: boolean;

	/**
	 * Proxy User Notification enabled
	 */
	proxy_user_notification_enabled: boolean;

	/**
	 * Is salesforce enabled on portal
	 */
	salesforce_enabled: boolean;

	/**
	 * Are associations enable on portal
	 */
	associations_enabled: boolean;

	/**
	 * Minimal attendance threshold flag for ILTs
	 */
	setting_minimal_threshold_for_ms_teams_enabled: boolean;

	/**
	 * Light feature flag for new go1 integration view
	 */
	go1_api_integration_enabled: boolean;

	/**
	 * Has access to Multi-Portal Progress Report
	 */
	multi_portal_progress_report: boolean;

	/**
	 *  Profile message showed on my profile page
	 */
	profile_message: string;

	/**
	 *  Learner Dashboard Enabled
	 */
	learner_dashboard_enabled: boolean;
	/**
	 * Light feature flag for new Licenses migration
	 */
	license_redesign_enabled: boolean;

	/**
	 *  Thumbnail images s3 url
	 */
	as3_main_bucket_s3_host: string;

	/**
	 *  Show/Hide release date on catalog page
	 */
	hide_release_dates_in_catalog: boolean;

	/**
	 *  Enable/Disable release date module on course page
	 */
	enable_module_release_dates: boolean;

	/**
	 * Portal switch for achievements section on Learner dashboard
	 */
	show_dashboard_widget: boolean;

	/**
	 *  Is gamification allowed
	 */
	gamification_allowed: boolean;

	/**
	 * Light feature flag for learning journeys
	 */
	learning_journey_enabled: boolean;

	/**
	 * My Learning feature flag enabled
	 */
	my_learning_enabled: boolean;

	/**
	 * Self award enabled
	 */
	self_award_enabled: boolean;

	/**
	 * Add credits button label
	 */
	add_credits_button_label: string;

	/**
	 * External trainings tab name
	 */
	credits_panel_name: string;

	/**
	 * TOS for ai question generation tool
	 */
	question_ai_generator_tos_accepted: boolean;

	/**
	 * Flag for TrainingHistory
	 */
	show_training_history: boolean;

	/**
	 * Allow Webhooks v2
	 */
	new_webhooks: boolean;

	/**
	 * Are Active user reports enabled/disabled?
	 */
	active_users_report_enabled: boolean;

	/**
	 * Shows My tasks section on Learner dashboard
	 */
	show_calendar_on_learner_dash: boolean;

	/**
	 * Allow to create ilts
	 */
	allow_to_create_ilts: boolean;

	/**
	 * Enable ILT Center functionality
	 */
	trainings_feature_enabled: boolean;

	/**
	 * Content details enabled
	 */
	content_details_redesign_enabled: boolean;

	/**
	 * Manager dashboard enabled
	 */
	manager_dashboard_enabled: boolean;

	/**
	 * Enable Custom ILT Data
	 */
	custom_ilt_data: boolean;

	/**
	 * Enable Bulk actions
	 */
	bulk_actions_enabled: boolean;

	/**
	 * Category vocabulary
	 */
	category_vocabulary: string;

	/**
	 * AI reflections feature enabled
	 */
	ai_reflections_enabled: boolean;

	/**
	 * Enable hide last updated resource date
	 */
	hide_last_updated_resource_date: boolean;

	/**
	 * Flag for enabling new certificate editor
	 */
	certificate_editor_redesign: boolean;

	/**
	 * Flag for enabling extended time options for SFTP import (CFA)
	 */
	sftp_import_allowed_extended_time_options: boolean;

	/**
	 * Flag for enabling Live event session series feature
	 */
	concurrent_and_series_session: boolean;
}
export interface PortalImage {
	id: number;
	portal_id: number;
	header: string;
	header2x: string;
	alt_tag: string;
	image_type: PortalImageType;
}

export interface AssociationMembershipType {
	name: string;
	value: number;
}

export enum PortalImageType {
	LOGO_SQUARED = 'logo_squared',
	LOGO = 'logo'
}

/**
 * Flash message Style
 *
 * Used to define flash message variations
 */
export enum FlashMessageStyle {
	DEFAULT = 1,
	NEWUITEASER1 = 2,
	IN_APP_SURVEY = 3
}

/**
 * Flash Message definition
 */
export interface FlashMessage {
	/**
	 * Id of specific flash message that is shown
	 */
	id: number;
	/**
	 * Message content - text for flash message
	 */
	message: string;
	/**
	 * Flash Message style - got various of these so UI varies based on this style
	 */
	style: FlashMessageStyle;
}

export enum CoursePublishedStatus {
	PUBLISHED = 1,
	DELETED = 2,
	DRAFT = 3,
	ARCHIVED = 4,
	UNDER_REVISION = 5
}

/**
 * Licenses - Resale statuses
 */
export enum LicenseResaleStatus {
	NOT_ALLOWED,
	ALLOWED_WITHOUT_PRICE_CHANGE,
	ALLOWED_WITH_PRICE_CHANGE
}

/**
 * Chat Integrations Type - define chat's type
 */
export enum ChatType {
	ZENDESK = 1,
	INTERCOM = 2
}

/**
 * Chat Settings definition - fetched from BE on initial app loading
 */
export interface ChatSettings {
	/**
	 * Active chat instance - chat that is connected
	 */
	active_chat: ChatType;
	/**
	 * Integration key
	 */
	active_chat_key: string;
}

export enum LuUserPermissions {
	CAN_MANAGE_TRAININGS = 'can_manage_trainings',
	CAN_MANAGE_SESSIONS = 'can_manage_sessions',
	CAN_ACT_AS_INSTRUCTOR = 'can_act_as_instructor',
	CAN_ONLY_MANAGE_ILTS = 'can_only_manage_ilts',
	TUTOR_CAN_CREATE_COURSES = 'tutor_can_create_courses',
	ADMIN_CAN_ACCESS_PORTAL_SETTINGS = 'admin_can_access_portal_settings'
}

export class LuUserPermissionsModel {
	can_manage_trainings?: boolean;
	can_manage_sessions?: boolean;
	can_act_as_instructor?: boolean;
	can_only_manage_ilts?: boolean;
	tutor_can_create_courses?: boolean;
	tutor_can_edit_their_courses?: boolean;
	can_create_courses?: boolean;
	can_edit_this_course?: boolean;
	can_unenroll_learner_from_course?: boolean;
	can_mark_complete?: boolean;
	can_delete_users?: boolean;
	can_enroll?: boolean;
	can_add_users_to_session?: boolean;
	admin_can_access_portal_settings?: boolean;
	can_manage_learning_journey?: boolean;
}

/**
 * Client Membership Action that user is choosing
 * when revoking admin
 */
export enum ClientMembershipActions {
	DO_NOT_UPDATE_MEMBERSHIPS = 1,
	UPDATE_MEMBERSHIPS = 2,
	DELETE_MEMBERSHIPS = 3
}

export class Location {
	id: number;
	title: string;
	timezone: string;
	address_1?: string;
	address_2?: string;
	address_3?: string;
	notes?: string;
}

export interface Certificate {
	id: number;
	title?: string;
	created_at?: string;
}

export enum DifficultyLevel {
	Basic = 1,
	Intermediate = 2,
	Advanced = 3
}

export class Timezone {
	id: string;
	name: string;
}
/**
 * CKEditor config model for dynamic loading paths to the required scripts and templates
 */
export interface CkEditor {
	url: string;
	basepath: string;
	skin: string;
	contents: string;
}

/**
 * Some default button interface
 */
export interface Button {
	label?: string;
	icon?: string;
	class?: string;
	disabled?: boolean;
	onClick?: (event?: Event, data?: any) => void;
}

/**
 * Some default link interface
 */
export interface Link {
	label?: string;
	link?: string;
}

/**
 * We're going to use routing state to pass data through app since we have it exposed inside pageWrapper
 */
export interface RoutingState {
	backButton?: Link;
}

/**
 * Timezone that we fetch and keep inside state service - this will serve as single source of truth
 */
export class LuStateTimezone {
	name: string;
	utc_offset: number;
}

export class LuStateTimezoneGMT extends LuStateTimezone {
	gmt: string;
}

export enum FormCols {
	COL_1 = 'col xs-12 sm-12 md-1 lg-1',
	COL_2 = 'col xs-12 sm-12 md-2 lg-2',
	COL_3 = 'col xs-12 sm-12 md-3 lg-3',
	COL_4 = 'col xs-12 sm-12 md-4 lg-4',
	COL_5 = 'col xs-12 sm-12 md-5 lg-5',
	COL_6 = 'col xs-12 sm-12 md-6 lg-6',
	COL_7 = 'col xs-12 sm-12 md-7 lg-7',
	COL_8 = 'col xs-12 sm-12 md-8 lg-8',
	COL_9 = 'col xs-12 sm-12 md-9 lg-9',
	COL_10 = 'col xs-12 sm-12 md-10 lg-10',
	COL_11 = 'col xs-12 sm-12 md-11 lg-11',
	COL_12 = 'col xm-12 sm-12 md-12 lg-12'
}

/**
 * Email template types
 */
export enum EmailTemplateType {
	INVITES_NEW_USER = '1',
	INVITES_TO_GROUP = '2',
	ENROLLMENTS = '3',
	ENROLLMENT_REMINDERS = '4',
	RESET_PASSWORD = '5',
	PASSWORD_WAS_RESET = '6',
	ASSIGNMENTS_SUBMITTED = '7',
	ASSIGNMENTS_REVIEWED = '8',
	CONFIRMATIONS = '9',
	PURCHASE_CONFIRMATION = '10',
	ENROLLMENT_COMPLETION = '11',
	EXAM_SUBMITTED = '12',
	COURSE_DUE_DATE_REMINDER = '13',
	PATH_DUE_DATE_REMINDER = '14',
	ENROLLMENT_RECERTIFICATION = '15',
	BULK_PURCHASE_EMAIL = '16',
	ENROLLMENT_APPROVAL_REJECT = '17',
	LEARNING_PATH_ENROLLMENTS = '18',
	COURSE_OVERDUE = '19',
	SESSION_REGISTRATION = '20',
	SESSION_REMINDER = '21',
	SESSION_UPDATE = '22',
	SESSION_CANCELLATION = '23',
	SESSION_REGISTRATION_CANCELLED = '24',
	SESSION_WL_LEARNER_JOINED = '25',
	SESSION_WL_LEARNER_LEFT = '26',
	SESSION_WL_CLOSED = '27',
	SESSION_WL_LEARNER_REMOVED = '28',
	SESSION_WL_LEARNER_ADDED = '29'
}
