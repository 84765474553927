import { SearchOptions } from '../models/angie-smart-search.models';

/**
 * Search Type Interface
 */
export interface SearchType {
	type: SearchOptions;
	translateKey: string;
	icon: string;
}

/**
 * Search Types inside global smart search
 */
export const searchTypes: SearchType[] = [
	{
		type: SearchOptions.COURSES,
		translateKey: 'pagetitles.courses',
		icon: 'lu-courses'
	},
	{
		type: SearchOptions.LEARNING_PATHS,
		translateKey: 'learningpaths.learning_paths',
		icon: 'lu-learning-paths'
	},
	{
		type: SearchOptions.CATALOG,
		translateKey: 'pagetitles.catalog',
		icon: 'lu-catalog'
	},
	{
		type: SearchOptions.RESOURCES,
		translateKey: 'pagetitles.resources',
		icon: 'lu-resources'
	},
	{
		type: SearchOptions.USERS,
		translateKey: 'pagetitles.users',
		icon: 'lu-users'
	},
	{
		type: SearchOptions.GROUPS,
		translateKey: 'pagetitles.groups',
		icon: 'lu-groups'
	}
];
