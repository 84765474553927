import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LuIconModule } from 'lu-icon';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AngieDropDownComponent } from './angie-drop-down.component';

const imports = [CommonModule, LuIconModule, TranslateModule, BsDropdownModule.forRoot()];
const declarations = [AngieDropDownComponent];
const exports = [AngieDropDownComponent];
@NgModule({
	imports,
	declarations,
	exports
})
export class AngieDropDownModule {}
